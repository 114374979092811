


export class BrowserAppEvaluationToolInfo {

  public version = '2023.10.19.1';

  constructor() {
  }

}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {DiageoFacilityProduct} from "../product.facility/model/DiageoFacilityProduct";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {TemplateProduct} from "../model.product/TemplateProduct";


export const environment: IProductEnvironment = {

  filename: 'environment.campus-444745.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: true,
    usernamePassword: true
  },

  firebaseConfig: {
    apiKey: "AIzaSyClLraUvvA1I_zgjm5fPUKogIPGgmoyLq0",
    authDomain: "campus-444745.firebaseapp.com",
    databaseURL: "https://campus-444745-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "campus-444745",
    storageBucket: "campus-444745.appspot.com",
    messagingSenderId: "913837212036",
    appId: "1:913837212036:web:aa0c0382e4f74436c25dd1"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-campus-444745.cloudfunctions.net',

  // nocoDbConfig: {
  //   // httpServer: 'http://localhost:8080',
  //   // httpServer: 'http://spot.local:8080',
  //   httpServer: 'https://mobilitymojo-spot.com',
  //   user: 'test@mobilitymojo.com',
  //   password: 'test@mobilitymojo.com',
  //   authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
  // },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/manufacturing-campus',
    product: TemplateProduct.INSTANCE,
    productType: EProductType.manufacturingCampus,
  },


};
